<template>
  <div class="field mb-1">
    <div class="control">
      <label class="checkbox consents">
        <checkbox class="checkbox-input" v-model="value"></checkbox>
        <div>
          <i18n :path="labelKey" tag="span" class="consent-text unselectable" :class="errorClass" @click="value = !value">
            <template v-if="hasSubstrings">
              <a target="_blank" @click.stop="0" v-for="keyValue in substringLinkMap" :href="keyValue.link" :key="keyValue.substring">{{keyValue.substring}}</a>
            </template>
          </i18n>
          <span class="consent-text has-text-link" @click.stop="collapsed = !collapsed" v-if="hasReadMore && !collapsed">
            {{ $t(readMoreButtonKey) }}
          </span>
          <i18n v-if="collapsed" :path="collapsedTextLabelKey" tag="span" class="consent-text unselectable" :class="errorClass" @click="value = !value">
            <template v-if="hasCollapsedTextSubstrings">
              <a target="_blank" @click.stop="0" v-for="keyValue in collapsedTextSubstringLinkMap" :href="keyValue.link" :key="keyValue.substring">{{keyValue.substring}}</a>
            </template>
          </i18n>
        </div>

      </label>
    </div>
  </div>
</template>

<script>
import Checkbox from "@/shared/components/form/Checkbox";
import FormBuilderConstants from "@/web/views/dev/formbuilder/form-builder-constants";

export default {
  name: "RegisterCheckboxInput",

  components: { Checkbox },

  props: {
    fieldData: {
      type: Object,
      required: true,
    },
    errorsVisible: {
      type: Boolean,
      required: true,
    },
    validation: {
      type: Object,
      required: true,
    },
    translationsNamespace: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      value: false,
      collapsed: false,
    };
  },

  computed: {
    fieldId() {
      return this.fieldData.id;
    },

    labelKey() {
      return `${this.translationsNamespace}.${this.fieldData.id}_label`;
    },

    collapsedTextLabelKey() {
      return `${this.translationsNamespace}.${this.fieldData.id}_collapsed_text_labels`;
    },

    readMoreButtonKey() {
      return `${this.translationsNamespace}.${this.fieldId}_button`;
    },

    hasReadMore() {
      return !!this.fieldData[FormBuilderConstants.FIELD_PROPERTIES.COLLAPSED];
    },

    substringsKey() {
      return `${this.translationsNamespace}.${this.fieldData.id}_substrings`;
    },

    linksKey() {
      return `${this.translationsNamespace}.${this.fieldData.id}_links`;
    },

    substringLinkMap() {
      const links = Object.values(this.$t(this.linksKey));
      const substrings = Object.values(this.$t(this.substringsKey));
      if (substrings && substrings.length && this.$t(this.substringsKey) !== this.substringsKey) {
        return substrings.map((substring, index) => {
          return {
            substring: substring,
            link: links[index],
          };
        });
      } else {
        return [];
      }
    },

    hasSubstrings() {
      return this.substringLinkMap.length > 0;
    },

    collapsedTextSubstringsKey() {
      return `${this.translationsNamespace}.${this.fieldData.id}_collapsed_substrings`;
    },

    collapsedTextLinksKey() {
      return `${this.translationsNamespace}.${this.fieldData.id}_collapsed_links`;
    },

    collapsedTextSubstringLinkMap() {
      const links = Object.values(this.$t(this.collapsedTextLinksKey));
      const substrings = Object.values(this.$t(this.collapsedTextSubstringsKey));
      if (substrings && substrings.length && this.$t(this.collapsedTextSubstringsKey) !== this.collapsedTextSubstringsKey) {
        return substrings.map((substring, index) => {
          return {
            substring: substring,
            link: links[index],
          };
        });
      } else {
        return [];
      }
    },

    hasCollapsedTextSubstrings() {
      return this.collapsedTextSubstringLinkMap.length > 0;
    },

    hasAcceptedValidation() {
      return FormBuilderConstants.VALIDATIONS.REQUIRED in this.validation;
    },

    errorClass() {
      return {
        "has-text-danger":
          this.errorsVisible && this.hasAcceptedValidation && !this.validation[FormBuilderConstants.VALIDATIONS.REQUIRED],
      };
    },
  },

  watch: {
    value(newValue) {
      this.$emit("input", newValue);
      this.$emit("form-input");
    },
  },
};
</script>
