export const Constants = Object.freeze({
  API_URL: "https://api.meetingapplication.com/api/v1/",
  API_HEADER_DEVICE_ID: "MeetingAppWidget",
  API_HEADER_ACCEPT_JSON: "application/json",

  //FETCHING STATES
  STATUS_NO_DATA: "status_no_data",
  STATUS_LOADING: "status_loading",
  STATUS_LOADED: "status_loaded",
  STATUS_ERROR: "status_error",

  //Component Names
  AGENDA_COMPONENT_TYPE: "AgendaComponent",
  EXHIBITORS_COMPONENT_TYPE: "ExhibitorComponent",
  SPEAKERS_COMPONENT_TYPE: "SpeakerComponent",
  BUSINESS_MATCHING_COMPONENT_TYPE: "BusinessMatchingComponent",

  //BUSINESS MATCHING
  BM_ACCEPTED_STATUS: "accepted",

  //Rest error codes
  AGENDA_SESSION_TICKET_LIMIT_EXCEEDED_ERROR_CODE: "buy_ticket.session_limit_places_exceeded",
  REST_TOKEN_EXPIRED: "token_expired",
  REST_AGENDA_SESSION_NO_ACCESS: "user_access.you_dont_have_access_for_component",

  //AGENDA SESSION RATING
  AGENDA_SESSION_RATING_TYPES: {
    CLOSED: "closed",
    STARS: "stars",
    OPEN: "open",
  },

  WIDGET_THEME_TYPES: {
    DEFAULT: "default",
    SCIENTIFIC: "scientific",
  },

  // Gateways
  GATEWAY_FREE_TICKET_ID: "free",

  GATEWAY_P24_ID: "p24",
  GATEWAY_P24_URL: process.env.VUE_APP_GATEWAY_P24_URL,

  GATEWAY_STRIPE_ID: "stripe",
  GATEWAY_STRIPE_KEY: process.env.VUE_APP_GATEWAY_STRIPE_KEY,

  GATEWAY_PAYU_ID: "payu",

  GATEWAY_BLUE_MEDIA_ID: "bluemedia",

  TRANSACTION_STATUSES: {
    PENDING: "PENDING",
    WAITING: "WAITING_FOR_CONFIRMATION",
    COMPLETED: "COMPLETED",
    CANCELED: "CANCELED",
  },

  PURCHASE_SUMMARY_ROW_FIELDS: {
    TICKET: "ticket",
    DISCOUNT: "discount",
    DISCOUNTED_TICKETS_QUANTITY: "discounted_tickets_quantity",
    QUANTITY: "quantity",
    MARKETING_DISCOUNT: "marketing_discount",
    ADDON_ROW: "addon_row",
    ADDON_MODEL: "addon_model",
    ADDON_QUANTITY: "addon_quantity",
    BULK_DISCOUNT: "bulk_discount",
  },

  PURCHASE_SECTION_INDEXES: {
    TICKET_SELECTION: 0,
    PERSONAL_INFORMATION: 1,
    TERMS_AND_DISCOUNTS: 2,
    SUMMARY: 3,
  },

  CUSTOM_FORMS_TRANSLATION_NAMESPACE: "custom_forms",

});

export default Constants;
